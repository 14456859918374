import keyBy from "lodash/keyBy";

import { FUSEKI_URL, WHITELISTED_DATA_SOURCES } from "../env";

export const PROD_DATA_SOURCE_URL =
  FUSEKI_URL ?? "http://localhost:3030/ds/query";

export const maybeMigrateSourceUrl = (url: string) => url;

export const SOURCE_OPTIONS = [
  {
    value: `sparql+https://lindas-cached.cluster.ldbar.ch/query`,
    label: "Prod",
    url: "sparql+https://lindas-cached.cluster.ldbar.ch/query",
    isTrusted: true,
    supportsCachingPerCubeIri: true,
  },
  {
    value: "sparql+https://lindas.admin.ch/query",
    label: "Prod-uncached",
    url: "https://lindas.admin.ch/query",
    isTrusted: true,
    supportsCachingPerCubeIri: true,
  },
  {
    value: "sparql+https://lindas-cached.int.cluster.ldbar.ch/query",
    label: "Int",
    url: "https://lindas-cached.int.cluster.ldbar.ch/query",
    isTrusted: false,
    supportsCachingPerCubeIri: true,
  },
  {
    value: "sparql+https://int.lindas.admin.ch/query",
    label: "Int-uncached",
    url: "https://int.lindas.admin.ch/query",
    isTrusted: false,
    supportsCachingPerCubeIri: true,
  },
  {
    value: "sparql+https://lindas-cached.test.cluster.ldbar.ch/query",
    label: "Test",
    url: "https://lindas-cached.test.cluster.ldbar.ch/query",
    isTrusted: false,
    supportsCachingPerCubeIri: true,
  },
  {
    value: "sparql+https://test.lindas.admin.ch/query",
    label: "Test-uncached",
    url: "https://test.lindas.admin.ch/query",
    isTrusted: false,
    supportsCachingPerCubeIri: true,
  },
  FUSEKI_URL
    ? {
        value: `sparql+${FUSEKI_URL}`,
        label: "Fuseki",
        url: FUSEKI_URL!,
        isTrusted: false,
        supportsCachingPerCubeIri: false,
      }
    : null,
].filter(<T extends { label: string }>(d: T | null): d is T =>
  WHITELISTED_DATA_SOURCES.includes(d && d.label)
);

export const SOURCES_BY_LABEL = keyBy(SOURCE_OPTIONS, (d) => d.label);
export const SOURCES_BY_VALUE = keyBy(SOURCE_OPTIONS, (d) => d.value);
export const SOURCES_BY_URL = keyBy(SOURCE_OPTIONS, (d) => d.url);
